import { remark } from "remark";
import remarkHtml from "remark-html";

export const createHTML = (data) => ({
  __html: remark().use(remarkHtml).processSync(data).toString(),
});

export const applyCustomMdEmphasis = (data) => {
  // add custom emphasis definitions here with their regex and accompanying function to convert the string
  let custEmphDict = [
    //strikethrough
    function (str) {
      return str.replace(
        /~{2}(.+)~{2}/gm,
        '<span class="strikethrough">$1</span>'
      );
    },
  ];

  for (var i = 0; i < custEmphDict.length; i++) {
    data = custEmphDict[i](data);
  }

  return data;
};

export const toKebabCase = (str) => {
  return str.replace(/ /g, "-").toLowerCase();
};
